<template>
  <div class="content">
    <moreHeader></moreHeader>
    <div class="content-box">
      <div class="title-box">
        <div class="title-name">智慧供水</div>
        <div class="title-p">
          生存与生活之间<br />
          也许只差一个媒介<br />
          农村 ( 城乡 ) 智慧供水系统解决方案<br />
          为百姓供好水
        </div>
      </div>
      <div class="function">
        <div class="scenes-box">
          <h3>方案优势</h3>
          <div class="scenes-bg">
            <div>
              <div class="leftTop">
                <img
                  class="iconss"
                  src="../../assets/assets/wisdomWater/mzhineng.png"
                  alt=""
                />
                智能
              </div>
              <div class="targetContent">
                智能远程管控、全自动运行减少运维、可视化管理
              </div>
            </div>

            <div>
              <div class="leftTop">
                <img
                  class="icons"
                  src="../../assets/assets/wisdomWater/gaoxiao.png"
                  alt=""
                />
                高效
              </div>
              <div class="targetContent">
                实时监测、在线预警、可关联水源地水质监测数据，匹配智能加药模型，优化药耗，产水安全可靠
              </div>
            </div>
            <div>
              <div class="leftTop">
                <img
                  class="iconss"
                  src="../../assets/image/wisdomWater/fw.png"
                  alt=""
                />
                服务
              </div>
              <div class="targetContent">
                配备设备管理、能耗成本管理、报警管理、报表管理、移动 app
                等，使业主从更加精细和动态的方式管理水厂的生产运行
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="function">
        <h3>平台架构</h3>
        <div class="services">
          <div class="left">
            <img
              style="width: 100%; height: 100%"
              @click="onYuLan(img)"
              :src="img"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="function">
        <h3>功能场景</h3>
      </div>
      <div class="scene">
        <div class="img1">
          <div class="sceneBox">
            <div class="sceneIcon icon1"></div>
            <div class="sceneTitle">水质安全</div>
            <div class="sceneContents">
              实时在线监测水质余氮、PH值、浊度等水质数据,确保供水质量。
            </div>
          </div>
        </div>

        <div class="img2">
          <div class="sceneBox">
            <div class="sceneIcon icon2"></div>
            <div class="sceneTitle">工况管理</div>
            <div class="sceneContents">
              监测设备运营,远程手动或逻辑控制水泵的启、停、阀门的开度等。
            </div>
          </div>
        </div>
        <div class="img3">
          <div class="sceneBox">
            <div class="sceneIcon icon3"></div>
            <div class="sceneTitle">园区安防</div>
            <div class="sceneContents">
              监测现场安防、设备巡检、现场环境等情况。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moreHeader from "../../components/header";
import { ImagePreview } from "vant";
export default {
  components: { moreHeader },
  name: "",
  data() {
    return { img: require("../../assets/image/wisdomWater/wsjg.png") };
  },

  methods: {
    onYuLan(img) {
      console.log(img);
      ImagePreview([img]);
    },
  },
};
</script>

<style scoped lang="less">
.content {
  box-sizing: border-box;
  margin-top: -27px;

  h3 {
    text-align: center;
    margin: 10px 0;
    letter-spacing: 5px;
    font-size: 18px;
  }

  .content-box {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .title-box {
      padding-top: 100px;
      width: 100%;
      color: #fff;
      height: 480px;

      background: url("../../assets/assets/wisdomWater/mwater.png") no-repeat;
      background-size: 100% 100%;
      .title-name {
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
        transform: translateY(30px);
        text-align: center;
        letter-spacing: 2px;
      }
      .title-p {
        font-size: 16px;

        transform: translateY(45px);
        color: #ffffff;
        text-align: center;
        letter-spacing: 2px;
        line-height: 20px;
      }
    }

    .function {
      background-color: #fff;
      h3 {
        padding: 10px;
      }
      .scenes-box {
        background-color: #fff;

        .scenes-bg {
          padding: 0 20px;

          // height: 170px;

          > div {
            width: 100%;
            //   height: 100%;
            height: 150px;
            background: url("../../assets/assets/wisdomWater/mbbj.png")
              no-repeat;
            background-size: 100% 100%;
            margin: 10px 0;
            .leftTop {
              width: 30%;
              height: 20px;
              line-height: 20px;
              text-align: left;
              font-size: 18px;
              color: #fff;
              font-weight: 400;
              border-bottom-right-radius: 13px;

              background-color: #c7b090;
              padding: 10px 0;
            }
            .targetContent {
              // margin: 10px;
              color: #6b6b6b;
              line-height: 18px;
              font-size: 16px;
              padding: 15px;
            }
          }
          .icons {
            width: 20px;
            height: 20px;
            vertical-align: middle;
            margin-left: 10px;
          }
          .iconss {
            width: 20px;
            height: 20px;
            vertical-align: middle;
            margin-bottom: 3px;
            margin-left: 10px;
          }
        }
        .scenes-text {
          width: 100%;
          height: 400px;
          text-align: center;
          background-color: #fff;
          .title {
            font-size: 28px;
            font-weight: 600;
            padding: 20px 0;
            color: #333333;
          }
          .text-box {
            display: flex;
            .text-box-item {
              width: 16%;
              font-size: 16px;
              color: #6b6b6b;
              margin-top: 60px;
              border-right: 1px solid #dfdfdf;
              p {
                padding: 10px 0;
              }
            }
          }
        }
      }
      .services {
        padding: 0 10px;

        background-color: #fff;
        .left {
          width: calc(100% - 20px);
          margin-left: 10px;
          height: 400px;
          margin-top: 4%;
          background: url("../../assets/image/wisdomWater/wsjg.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .nh-text {
        font-size: 14px;
        margin: 10px 0;
        color: #6b6b6b;
      }
    }
  }

  .scene {
    padding: 0 10px;
    margin-bottom: 10px;
    //   overflow: hidden;
    .sceneBox {
      position: relative;
      margin: 5px 0;

      .sceneContents {
        padding: 5px 15px;
        color: white;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
      }

      .sceneIcon {
        width: 42px;
        height: 42px;
        margin-top: 25px;
      }
      .sceneTitle {
        position: relative;
        padding-top: 80px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin: 5px 0;
        opacity: 1;

        box-sizing: border-box;
        padding-bottom: 10px;
        text-align: center;
        //   border-bottom: 2px solid #fff;
        &::before {
          position: absolute;
          content: "";
          width: 240px;
          height: 1px;
          background-color: #fff;
          bottom: 1%;
          left: 50%;
          transform: translate(-50%, 50%);
        }
      }
      .sceneContent {
        color: #fff;
        margin: 20px 40px;
        z-index: 99;
        display: none;
      }
    }
  }
  .img1 {
    height: 210px;
    background: url("../../assets/assets/wisdomWater/szafbg.png") no-repeat;
    background-size: 100% 100%;
    // margin: 0 5%;
  }
  .img2 {
    height: 210px;
    background: url("../../assets/assets/wisdomWater/gkglbg.png") no-repeat;
    background-size: 100% 100%;
    //  margin: 0 5%;
  }
  .img3 {
    height: 210px;
    background: url("../../assets/assets/wisdomWater/yqafbg.png") no-repeat;
    background-size: 100% 100%;
    //  margin: 0 5%;
  }

  .icon1 {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%);

    background: url("../../assets/assets/wisdomWater/szaf.png") no-repeat;
    background-size: 100% 100%;
  }
  .icon2 {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%);
    background: url("../../assets/assets/wisdomWater/gkgl.png") no-repeat;
    background-size: 100% 100%;
  }
  .icon3 {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%);

    background: url("../../assets/assets/wisdomWater/yqaf.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
